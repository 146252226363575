var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Invoices")]),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(
                _vm.permissions &&
                  _vm.permissions.invoice &&
                  _vm.permissions.invoice.canCreate
              )?_c('router-link',{attrs:{"to":("/customers/" + _vm.customerId + "/invoices/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("Upload New Invoice ")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.viewPeriodItems,"item-text":"name","item-value":"value","label":"Choose Period..."},model:{value:(_vm.viewPeriod),callback:function ($$v) {_vm.viewPeriod=$$v},expression:"viewPeriod"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(_vm.viewPeriod == 'CD')?_c('div',[_c('v-menu',{ref:"customDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Choose date range","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formattedCustomDates),callback:function ($$v) {_vm.formattedCustomDates=$$v},expression:"formattedCustomDates"}},'v-text-field',attrs,false),on))]}}],null,false,667435010),model:{value:(_vm.customDateMenu),callback:function ($$v) {_vm.customDateMenu=$$v},expression:"customDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.customDates),callback:function ($$v) {_vm.customDates=$$v},expression:"customDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.customDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.customDateMenu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalRecords,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(!item.fileUrl)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadInvoice(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-download")])],1):_c('a',_vm._g(_vm._b({staticClass:"ma-2 pa-2  ",staticStyle:{"display":"inline-block"},attrs:{"target":"_blank","href":item.fileUrl}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Invoice")])])]}},{key:"item.invoiceDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateUs(item.invoiceDate))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(
                    item &&
                      _vm.communityInfo &&
                      _vm.communityInfo.propertyManager &&
                      _vm.communityInfo.propertyManager.email
                  )?_c('a',{attrs:{"target":"_blank","href":("mailto:" + (_vm.communityInfo.propertyManager.email))}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#31C0E3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-email ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Email invoice")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.fileUrl)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();item.deleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete Invoice ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete invoice: "),_c('p',[_vm._v(_vm._s(item.title))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteInvoice(item)}}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }